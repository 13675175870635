import React, { useEffect, useState } from "react";
import {
  selectRemotePeers,
  useAutoplayError,
  useHMSStore,
} from "@100mslive/react-sdk";
import { Button, Dialog, Text } from "@100mslive/roomkit-react";
import { DialogContent, DialogRow } from "../../primitives/DialogContent";

export function AutoplayBlockedModal() {
  const { error, resetError, unblockAudio } = useAutoplayError();
  const peers = useHMSStore(selectRemotePeers);

  const [hostName, setHostName] = useState("host");

  useEffect(() => {
    const hosts = peers.filter(e => e.roleName === "host");
    if (hosts.length > 0) setHostName(hosts[0].name);
  }, [peers]);

  return (
    <Dialog.Root
      open={!!error}
      onOpenChange={value => {
        if (!value) {
          unblockAudio();
        }
        resetError();
      }}
    >
      <DialogContent closeable={false}>
        <DialogRow>
          <Text variant="md">
            You would be able to see and hear {hostName}, but your video/audio
            will not be shared.
          </Text>
        </DialogRow>
        <DialogRow>
          <Text variant="md">
            You could reply to {hostName} using the text chat.
          </Text>
        </DialogRow>
        <DialogRow justify="end">
          <Button
            variant="primary"
            onClick={() => {
              unblockAudio();
              resetError();
            }}
          >
            Let's talk
          </Button>
        </DialogRow>
      </DialogContent>
    </Dialog.Root>
  );
}
